import RequireAuth from 'commons/auth/RequireAuth'

import MoneyTransferPage from './containers/MoneyTransferPage'
import InternationalTransferPage from './containers/InternationalTransferPage'
import SpecialTransferPage from './containers/SpecialTransferPage'
import AgentTransferPage from './containers/AgentTransferPage'
import MoneyTransferListPage from './containers/MoneyTransferListPage'
import SpecialMoneyTransferListPage from './containers/SpecialMoneyTransferListPage'
import AgentMoneyTransferListPage from './containers/AgentMoneyTransferListPage'
import InternationalMoneyTransferListPage from './containers/InternationalMoneyTransferListPage'

const moneyTransferRoutes = [
  {
    path: '/money-transfer',
    element: <MoneyTransferPage />,
  },
  {
    path: '/international-transfer',
    element: <InternationalTransferPage />,
  },
  {
    path: '/agent-transfer',
    element: <AgentTransferPage />,
  },
  {
    path: '/special-transfer',
    element: <SpecialTransferPage />,
  },
  {
    path: '/money-transfer/list',
    element: <MoneyTransferListPage />,
  },

  {
    path: '/agent-money-transfer/list',
    element: <AgentMoneyTransferListPage />,
  },

  {
    path: '/special-money-transfer/list',
    element: <SpecialMoneyTransferListPage />,
  },

  {
    path: '/international-money-transfer/list',
    element: <InternationalMoneyTransferListPage />,
  },
]

export default moneyTransferRoutes
