import axios from 'axios'
import tokenManager from 'commons/utils/token'
import environment from 'commons/utils/environment'

const createVirtualAccount = (data = {}) => {
  let body = data
  console.log(body)

  const { getToken } = tokenManager()
  const token = getToken()
  return axios.post(`${environment.rootApi}/call/virtualaccount`, body, {
    params: {
      token,
    },
  })
}

export default createVirtualAccount
