import axios from 'axios'
import tokenManager from 'commons/utils/token'
import environment from 'commons/utils/environment'

const updateStatus = (data = {}) => {
  let body = data
  console.log(data)

  const { getToken } = tokenManager()
  const token = getToken()
  return axios.post(
    `${environment.rootApi}/call/money-transfer/update-status`,
    body,
    {
      params: { token },

      headers: {
        Authorization: token,
      },
    }
  )
}

export default updateStatus
