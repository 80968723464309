import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const SpecialMoneyTransferTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.sender_name}</TableCell>
      {/* <TableCell>{item?.sender_country}</TableCell> */}
      <TableCell>{item?.sender_address}</TableCell>
      <TableCell>{item?.sender_job}</TableCell>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.bank_code}</TableCell>
      <TableCell>{item?.account_number}</TableCell>
      <TableCell>{item?.direction}</TableCell>
      <TableCell>{item?.status}</TableCell>
    </TableRow>
  )
}

export default SpecialMoneyTransferTable
