import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const EWalletTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.phoneNumber}</TableCell>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.eWalletUrl}</TableCell>
      <TableCell>{item?.eWalletType}</TableCell>
      <TableCell>{item?.productName}</TableCell>
    </TableRow>
  )
}

export default EWalletTable
