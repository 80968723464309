import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import createVirtualAccount from '../services/createVirtualAccount'

const FormVirtualAccount = ({}) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()

  const kirim = data => {
    const cleanData = cleanFormData(data)
    createVirtualAccount({
      ...cleanData,
    })
      .then(({ data: { data } }) => {
        navigate(`/virtual-account/list`)
      })
      .catch(error => {
        toast.error(
          error.response?.data?.data?.message ||
            error ||
            'Terjadi kesalahan pada sistem. Harap coba lagi!'
        )
      })
  }

  return (
    <Form title="Create Virtual Account Payment" onSubmit={handleSubmit(kirim)}>
      <Controller
        name="amount"
        control={control}
        rules={{ required: 'Masukan jumlah pembayaran' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah"
            placeholder="Nominal"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="bank"
        control={control}
        rules={{ required: 'Masukan nama bank' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Nama Bank"
            options={[
              { id: 'bca', name: 'BCA' },
              { id: 'mandiri', name: 'Mandiri' },
              { id: 'bni', name: 'BNI' },
              { id: 'bri', name: 'BRI' },
              { id: 'permata', name: 'Permata' },
            ]}
            placeholder="Pilih Bank"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="product_name"
        control={control}
        rules={{ required: 'Harap masukkan produk yang diinginkan' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Nama Produk"
            options={[
              { id: 'Midtrans', name: 'Midtrans' },
              { id: 'Oy', name: 'Oy' },
            ]}
            placeholder="Pilih Nama Produk"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <div className="card-actions justify-end">
        <Button type="submit" variant="primary">
          Kirim
        </Button>
      </div>
    </Form>
  )
}

export default FormVirtualAccount
