import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const PaymentLinkTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.paymentLink}</TableCell>
      <TableCell>{item?.productName}</TableCell>
    </TableRow>
  )
}

export default PaymentLinkTable
