import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getDisbursement from 'disbursement/services/getDisbusement'
import MoneyTransferTable from 'disbursement/components/MoneyTransferTable'
import getDisbursementFromAPI from 'disbursement/services/getDisbusementFromAPI'
import updateStatus from 'disbursement/services/updateStatus'

const MoneyTransferListPage = props => {
  const [moneyTransfer, setMoneyTransfer] = useState([])

  useEffect(() => {
    const fetch = async () => {
      await updateStatus()
      const { data: moneyTransferResponse } = await getDisbursement({
        table_name: 'moneytransfer_impl',
      })
      setMoneyTransfer(moneyTransferResponse.data)

      // console.log(moneyTransferResponse)
    }

    fetch()
  }, [])

  const update = async () => {
    const disbursements = await getDisbursementFromAPI()
    // Object.keys().map((key, index) => {
    //   console.log("key: " + disbursements.data[key] + ", value: " + )
    // })
    console.log(disbursements.data)
    // window.location.reload(true)
  }

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Money Transfer List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ moneyTransfer }} />
      </div>
    </div>
  )
}

const TableView = ({ moneyTransfer }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="bank_code">Kode Bank</TableCell>
              <TableCell id="account_number">Nomor Rekening Tujuan </TableCell>
              <TableCell id="status">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moneyTransfer &&
              moneyTransfer.map(item => (
                <MoneyTransferTable key={item.id} item={item} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default MoneyTransferListPage
