import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getDisbursement from 'disbursement/services/getDisbusement'
import SpecialMoneyTransferTable from 'disbursement/components/SpecialMoneyTransferTable'
import updateStatus from 'disbursement/services/updateStatus'
const SpecialMoneyTransferListPage = props => {
  // const { control, handleSubmit } = useForm()
  const [specialMoneyTransfer, setSpecialMoneyTransfer] = useState([])

  useEffect(() => {
    const fetch = async () => {
      await updateStatus()
      const { data: specialMoneyTransferResponse } = await getDisbursement({
        table_name: 'special_impl',
      })
      setSpecialMoneyTransfer(specialMoneyTransferResponse.data)
    }

    fetch()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Special Money Transfer List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ specialMoneyTransfer }} />
      </div>
    </div>
  )
}

const TableView = ({ specialMoneyTransfer }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="sender_name">Nama Pengirim</TableCell>
              <TableCell id="sender_address">Alamat Pengirim</TableCell>
              <TableCell id="sender_job">Pekerjaan Pengirim</TableCell>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="bank_code">Kode Bank</TableCell>
              <TableCell id="account_number">Nomor Rekening Tujuan </TableCell>
              <TableCell id="direction"> Tipe Transfer </TableCell>
              <TableCell id="status">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialMoneyTransfer &&
              specialMoneyTransfer.map(item => (
                <SpecialMoneyTransferTable key={item.id} item={item} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default SpecialMoneyTransferListPage
