import axios from 'axios'
import tokenManager from 'commons/utils/token'
import environment from 'commons/utils/environment'

const createMoneyTransfer = (data = {}) => {
  let body = data
  console.log(body)

  const { getToken } = tokenManager()
  const token = getToken()
  return axios.post(`${environment.rootApi}/call/money-transfer`, body, {
    params: {
      token,
    },

    headers: {
      Authorization: token,
    },
  })
}

export default createMoneyTransfer
