import React from 'react'

import FormInternationalTransfer from '../components/FormInternationalTransfer'
import getCountryList from 'disbursement/services/getCountryList'
import { useEffect, useState } from 'react'

const InternationalTransferPage = props => {
  const [countries, setCountries] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const { data: countryResponse } = await getCountryList({})

      let countryList = []
      for (const [key, value] of Object.entries(countryResponse)) {
        countryList.push({ id: key, name: value })
      }
      setCountries(countryList)
    }

    fetch()
  }, [])

  return <FormInternationalTransfer {...{ countries }} {...props} />
}

export default InternationalTransferPage
