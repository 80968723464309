import axios from 'axios'
import tokenManager from 'commons/utils/token'
import environment from 'commons/utils/environment'

const getCountryList = () => {
  return axios
    .get(`https://bigflip.id/big_sandbox_api/v2/disbursement/country-list`, {
      headers: {
        Authorization:
          'Basic SkRKNUpERXpKR3A0YlU5WVppNU9kRGRuU0VoU2JYbFBibXhEVVM1VVJGaHRTM0pEZFZwc2NWVTFMemgxUldwSVVqVldielpMYkhOMkwybDE6',
      },
    })
    .catch(error => {
      console.error(error)
    })
}

export default getCountryList
