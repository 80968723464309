const menus = [
  {
    route: '#',
    label: 'Transfer',
    subMenus: [
      {
        route: '/money-transfer',
        label: 'Money Transfer',
      },
      {
        route: '/international-transfer',
        label: 'International Transfer',
      },
      {
        route: '/agent-transfer',
        label: 'Agent Money Transfer',
      },
      {
        route: '/special-transfer',
        label: 'Special Transfer',
      },
    ],
  },

  {
    route: '#',
    label: 'Payment',
    subMenus: [
      {
        route: '/payment-link',
        label: 'Payment Link',
      },
      {
        route: '/ewallet',
        label: 'E-Wallet',
      },
      {
        route: '/retail-outlet',
        label: 'Retail Outlet',
      },
      {
        route: '/virtual-account',
        label: 'Virtual Account',
      },
    ],
  },

  {
    route: '#',
    label: 'Detail Transfer',
    subMenus: [
      {
        route: '/money-transfer/list',
        label: 'Money Transfer',
      },
      {
        route: '/international-money-transfer/list',
        label: 'International Transfer',
      },
      {
        route: '/agent-money-transfer/list',
        label: 'Agent Money Transfer',
      },
      {
        route: '/special-money-transfer/list',
        label: 'Special Transfer',
      },
    ],
  },

  {
    route: '#',
    label: 'Detail Payment',
    subMenus: [
      {
        route: '/payment-link/list',
        label: 'Payment Link',
      },
      {
        route: '/ewallet/list',
        label: 'E-Wallet',
      },
      {
        route: '/retail-outlet/list',
        label: 'Retail Outlet',
      },
      {
        route: '/virtual-account/list',
        label: 'Virtual Account',
      },
    ],
  },
]

export const settingsMenu = [
  {
    route: '#',
    label: 'Pengaturan',
    subMenus: [
      {
        route: '/settings/appearance',
        label: 'Pengaturan Tampilan',
      },
      {
        route: '/settings/role',
        label: 'Pengaturan Role',
      },
      {
        route: '/settings/user',
        label: 'Pengaturan User',
      },
    ],
  },
]

export default menus
