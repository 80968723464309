import axios from 'axios'
import tokenManager from 'commons/utils/token'
import environment from 'commons/utils/environment'

const createSpecialTransfer = (data = {}) => {
  let body = data

  const { getToken } = tokenManager()
  const token = getToken()
  return axios.post(
    `${environment.rootApi}/call/special-money-transfer`,
    body,
    {
      params: { token },

      headers: {
        Authorization: token,
      },
    }
  )
}

export default createSpecialTransfer
