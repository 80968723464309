import { useRoutes } from 'react-router-dom'

import moneyTransferRoutes from 'disbursement/routes.js'
import commonRoutes from 'commons/routes.js'
import userRoutes from 'user/routes'
import roleRoutes from 'role/routes'
import paymentRoutes from 'payment/routes'

const GlobalRoutes = () => {
  const router = useRoutes([
    ...moneyTransferRoutes,
    ...paymentRoutes,
    ...commonRoutes,
    ...userRoutes,
    ...roleRoutes,
  ])

  return router
}

export default GlobalRoutes
