import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import createSpecialTransfer from '../services/createSpecialTransfer'

const FormSpecialTransfer = ({ countries }) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()

  const kirim = data => {
    const cleanData = cleanFormData(data)
    console.log(cleanData)
    createSpecialTransfer({
      ...cleanData,
    })
      .then(({ data: { data } }) => {
        navigate(`/special-money-transfer/list`)
      })
      .catch(error => {
        toast.error(
          error.response?.data?.data?.message ||
            error ||
            'Terjadi kesalahan pada sistem. Harap coba lagi!'
        )
      })
  }

  return (
    <Form title="Special Transfer" onSubmit={handleSubmit(kirim)}>
      <Controller
        name="amount"
        control={control}
        rules={{ required: 'Harap masukkan jumlah transfer' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah Transfer"
            placeholder="Masukkan jumlah transfer"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="account_number"
        control={control}
        rules={{ required: 'Harap masukkan nomor destinasi' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Destinasi"
            placeholder="Masukkan nomor destinasi"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="bank_code"
        control={control}
        rules={{ required: 'Harap masukkan Kode bank' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Kode Bank"
            placeholder="Masukkan kode bank"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_country"
        control={control}
        rules={{ required: 'Harap pilih destinasi negara' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Asal Negara Pengirim"
            options={countries}
            placeholder="Pilih Asal Negara"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_name"
        control={control}
        rules={{ required: 'Harap masukkan nama pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nama Pengirim"
            placeholder="Masukkan nama pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_address"
        control={control}
        rules={{ required: 'Harap masukkan alamat pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Alamat Pengirim"
            placeholder="Masukkan alamat pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_job"
        control={control}
        rules={{ required: 'Harap masukkan pekerjaan pengirim' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Pekerjaan Pengirim"
            options={[
              { id: 'housewife', name: 'Ibu Rumah Tangga' },
              { id: 'entrepreneur', name: 'Wiraswasta' },
              { id: 'private_employee', name: 'Pegawai Swasta' },
              { id: 'government_employee', name: 'Pegawai Negeri' },
              { id: 'foundation_board', name: 'Pegawai Yayasan' },
              { id: 'indonesian_migrant_worker', name: 'TKI' },
              { id: 'others', name: 'lainnya' },
            ]}
            placeholder="Pilih Pekerjaan Pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="direction"
        control={control}
        rules={{ required: 'Harap pilih destinasi negara' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Tipe Transfer"
            options={[
              {
                id: 'DOMESTIC_SPECIAL_TRANSFER',
                name: 'Domestic Special Transfer',
              },
              {
                id: 'FOREIGN_INBOUND_SPECIAL_TRANSFER',
                name: 'Foreign Inbound Special Transfer',
              },
            ]}
            placeholder="Pilih Tipe Transfer"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="product_name"
        control={control}
        rules={{ required: 'Harap masukkan nama produk' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Nama Produk"
            options={[{ id: 'Flip', name: 'Flip' }]}
            placeholder="Pilih Nama Produk"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <div className="card-actions justify-end">
        <Button type="submit" variant="primary">
          Kirim
        </Button>
      </div>
    </Form>
  )
}

export default FormSpecialTransfer
