import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getPayment from 'payment/services/getPayment'
import RetailOutletTable from 'payment/components/RetailOutletTable'
// import getDisbursementFromAPI from 'disbursement/services/getDisbusementFromAPI'
// import updateStatus from 'disbursement/services/updateStatus'

const RetailOutletListPage = props => {
  const [retailOutlet, setretailOutlet] = useState([])

  useEffect(() => {
    const fetch = async () => {
      // await updateStatus()
      const { data: RetailOutletResponse } = await getPayment({
        table_name: 'retailoutlet_impl',
      })
      setretailOutlet(RetailOutletResponse.data)

      console.log(RetailOutletResponse)
    }

    fetch()
  }, [])

  // const update = async () => {
  //   const disbursements = await getDisbursementFromAPI()
  //   // Object.keys().map((key, index) => {
  //   //   console.log("key: " + disbursements.data[key] + ", value: " + )
  //   // })
  //   console.log(disbursements.data)
  //   // window.location.reload(true)
  //   }

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Retail Outlet List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ retailOutlet }} />
      </div>
    </div>
  )
}

const TableView = ({ retailOutlet }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="retailOutlet">Nama Retail Outlet</TableCell>
              <TableCell id="retailPaymentCode">Kode Pembayaran</TableCell>
              <TableCell id="productName">Nama Produk</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {retailOutlet &&
              retailOutlet.map(item => (
                <RetailOutletTable key={item.id} item={item} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default RetailOutletListPage
