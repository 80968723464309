import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getDisbursement from 'disbursement/services/getDisbusement'
import AgentMoneyTransferTable from 'disbursement/components/AgentMoneyTransferTable'
import updateStatus from 'disbursement/services/updateStatus'

const MoneyTransferListPage = props => {
  const [agentMoneyTransfer, setAgentMoneyTransfer] = useState([])

  useEffect(() => {
    const fetch = async () => {
      await updateStatus()
      const { data: moneyTransferResponse } = await getDisbursement({
        table_name: 'agent_impl',
      })
      setAgentMoneyTransfer(moneyTransferResponse.data)
    }

    fetch()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Agent Money Transfer List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ agentMoneyTransfer }} />
      </div>
    </div>
  )
}

const TableView = ({ agentMoneyTransfer }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="bank_code">Kode Bank</TableCell>
              <TableCell id="account_number">Nomor Rekening Tujuan </TableCell>
              <TableCell id="direction">Tipe Transfer </TableCell>
              <TableCell id="status">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agentMoneyTransfer &&
              agentMoneyTransfer.map(item => (
                <AgentMoneyTransferTable key={item.id} item={item} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default MoneyTransferListPage
