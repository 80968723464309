import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getPayment from 'payment/services/getPayment'
import PaymentLinkTable from 'payment/components/PaymentLinkTable'
// import getDisbursementFromAPI from 'disbursement/services/getDisbusementFromAPI'
// import updateStatus from 'disbursement/services/updateStatus'

const PaymentLinkListPage = props => {
  const [paymentLink, setPaymentLink] = useState([])

  useEffect(() => {
    const fetch = async () => {
      // await updateStatus()
      const { data: PaymentLinkResponse } = await getPayment({
        table_name: 'paymentlink_impl',
      })
      setPaymentLink(PaymentLinkResponse.data)

      console.log(PaymentLinkResponse)
    }

    fetch()
  }, [])

  // const update = async () => {
  //   const disbursements = await getDisbursementFromAPI()
  //   // Object.keys().map((key, index) => {
  //   //   console.log("key: " + disbursements.data[key] + ", value: " + )
  //   // })
  //   console.log(disbursements.data)
  //   // window.location.reload(true)
  //   }

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">Payment Link List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ paymentLink }} />
      </div>
    </div>
  )
}

const TableView = ({ paymentLink }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="paymentLink">Link</TableCell>
              <TableCell id="productName">Nama Produk</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentLink &&
              paymentLink.map(item => (
                <PaymentLinkTable key={item.id} item={item} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default PaymentLinkListPage
