import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const RetailOutletTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.retailOutlet}</TableCell>
      <TableCell>{item?.retailPaymentCode}</TableCell>
      <TableCell>{item?.productName}</TableCell>
    </TableRow>
  )
}

export default RetailOutletTable
