import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import createEWallet from '../services/createEWallet'

const FormEWallet = ({}) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()

  const kirim = data => {
    const cleanData = cleanFormData(data)
    createEWallet({
      ...cleanData,
    })
      .then(({ data: { data } }) => {
        navigate(`/ewallet/list`)
      })
      .catch(error => {
        toast.error(
          error.response?.data?.data?.message ||
            error ||
            'Terjadi kesalahan pada sistem. Harap coba lagi!'
        )
      })
  }

  return (
    <Form title="Create E-Wallet Payment" onSubmit={handleSubmit(kirim)}>
      <Controller
        name="amount"
        control={control}
        rules={{ required: 'Masukan jumlah pembayaran' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah"
            placeholder="Nominal"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="phone_number"
        control={control}
        rules={{ required: 'Masukan Nomor Pelanggan' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Pelanggan"
            placeholder="Nomor Pelanggan"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="ewalletType"
        control={control}
        rules={{ required: 'Masukan Jenis E-Wallet' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Nama E-Wallet"
            options={[
              { id: 'Gopay', name: 'Gopay' },
              { id: 'shopeepay', name: 'ShopeePay' },
              { id: 'ovo', name: 'OVO' },
            ]}
            placeholder="Pilih Jenis E-Wallet"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="product_name"
        control={control}
        rules={{ required: 'Harap masukkan produk yang diinginkan' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Nama Produk"
            options={[
              { id: 'Midtrans', name: 'Midtrans' },
              { id: 'Oy', name: 'Oy' },
            ]}
            placeholder="Pilih Nama Produk"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <div className="card-actions justify-end">
        <Button type="submit" variant="primary">
          Kirim
        </Button>
      </div>
    </Form>
  )
}

export default FormEWallet
