import React from 'react'

import FormSpecialTransfer from '../components/FormSpecialTransfer'
import getCountryList from 'disbursement/services/getCountryList'
import { useEffect, useState } from 'react'

const SpecialTransferPage = props => {
  const [countries, setCountries] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const { data: countryResponse } = await getCountryList({})

      let countryList = []
      for (const [key, value] of Object.entries(countryResponse)) {
        countryList.push({ id: key, name: value })
      }
      setCountries(countryList)
      //   console.log(countryList)
      // console.log(countries)
    }

    fetch()
  }, [])
  // console.log(countries)
  return <FormSpecialTransfer {...{ countries }} {...props} />
}

export default SpecialTransferPage
