import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const AgentMoneyTransferTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.bank_code}</TableCell>
      <TableCell>{item?.account_number}</TableCell>
      <TableCell>{item?.direction}</TableCell>
      <TableCell>{item?.status}</TableCell>
    </TableRow>
  )
}

export default AgentMoneyTransferTable
