import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getDisbursement from 'disbursement/services/getDisbusement'
import InternationalMoneyTransferTable from 'disbursement/components/InternationalMoneyTransferTable'
import updateStatus from 'disbursement/services/updateStatus'

const InternationalMoneyTransferListPage = props => {
  // const { control, handleSubmit } = useForm()
  const [internationalMoneyTransfer, setInternationalMoneyTransfer] = useState(
    []
  )

  useEffect(() => {
    const fetch = async () => {
      await updateStatus()
      const {
        data: internationalMoneyTransferResponse,
      } = await getDisbursement({ table_name: 'international_impl' })
      setInternationalMoneyTransfer(internationalMoneyTransferResponse.data)
    }

    fetch()
  }, [])

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">international Money Transfer List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ internationalMoneyTransfer }} />
      </div>
    </div>
  )
}

const TableView = ({ internationalMoneyTransfer }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="bank_code">Kode Bank</TableCell>
              <TableCell id="account_number">Nomor Rekening Tujuan </TableCell>
              <TableCell id="source_country">Negara tujuan </TableCell>
              <TableCell id="destination_country">Negara Asal </TableCell>
              <TableCell id="exchange_rate">Nilai Tukar Mata Uang </TableCell>
              <TableCell id="beneficiary_currency_code">
                Mata Uang Penerima
              </TableCell>
              <TableCell id="amount_in_sender_currency">
                Nominal dalam Satuan Pengirim
              </TableCell>
              <TableCell id="fee">biaya</TableCell>
              {/* <TableCell id="direction"> Tipe Transfer </TableCell> */}
              <TableCell id="status">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {internationalMoneyTransfer &&
              internationalMoneyTransfer.map(item => (
                <InternationalMoneyTransferTable key={item.id} item={item} />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default InternationalMoneyTransferListPage
