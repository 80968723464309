import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const InternationalMoneyTransferTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.bank_code}</TableCell>
      <TableCell>{item?.account_number}</TableCell>
      <TableCell>{item?.source_country}</TableCell>
      <TableCell>{item?.destination_country}</TableCell>
      <TableCell>{item?.exchange_rate}</TableCell>
      <TableCell>{item?.beneficiary_currency_code}</TableCell>
      <TableCell>{item?.amount_in_sender_currency}</TableCell>
      <TableCell>{item?.fee}</TableCell>
      <TableCell>{item?.status}</TableCell>
    </TableRow>
  )
}

export default InternationalMoneyTransferTable
