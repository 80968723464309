import React from 'react'

import { TableRow, TableCell } from 'commons/components'

const VirtualAccountTable = ({ item }) => {
  return (
    <TableRow distinct={false}>
      <TableCell>{item?.amount}</TableCell>
      <TableCell>{item?.bankCode}</TableCell>
      <TableCell>{item?.vaAccountNumber}</TableCell>
      <TableCell>{item?.productName}</TableCell>
    </TableRow>
  )
}

export default VirtualAccountTable
