import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DetailRole from '../components/DetailRole'
import getRoleDetail from '../services/getRoleDetail'

const DetailRolePage = props => {
  const [role, setRole] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data: roleDetail } = await getRoleDetail({ id })
      //line 12 data: roleDetail sma dgn 13
      //const roleDetail = data
      setRole(roleDetail.data)
    }
    fetchData()
  }, [])

  return role ? (
    <div className="prose max-w-screen-lg mx-auto p-6">
      <h2>Detail Role</h2>
      <DetailRole {...{ role }} />
    </div>
  ) : (
    <></>
  )
}

export default DetailRolePage
