import axios from 'axios'
import tokenManager from 'commons/utils/token'
import environment from 'commons/utils/environment'

const getPayment = (data = {}) => {
  let body = data
  console.log(data)

  const { getToken } = tokenManager()
  const token = getToken()
  return axios.post(`${environment.rootApi}/call/payment/list`, body, {
    params: { token },
  })
}

export default getPayment
