import PaymentLinkPage from './containers/PaymentLinkPage'
import EWalletPage from './containers/EWalletPage'
import RetailOutletPage from './containers/RetailOutletPage'
import VirtualAccountPage from './containers/VirtualAccountPage'
import PaymentLinkListPage from './containers/PaymentLinkListPage'
import EWalletListPage from './containers/EWalletListPage'
import RetailOutletListPage from './containers/RetailOutletListPage'
import VirtualAccountListPage from './containers/VirtualAccountListPage'

const paymentRoutes = [
  {
    path: '/payment-link',
    element: <PaymentLinkPage />,
  },

  {
    path: '/ewallet',
    element: <EWalletPage />,
  },

  {
    path: '/retail-outlet',
    element: <RetailOutletPage />,
  },

  {
    path: '/virtual-account',
    element: <VirtualAccountPage />,
  },

  {
    path: '/payment-link/list',
    element: <PaymentLinkListPage />,
  },

  {
    path: '/ewallet/list',
    element: <EWalletListPage />,
  },

  {
    path: '/retail-outlet/list',
    element: <RetailOutletListPage />,
  },

  {
    path: '/virtual-account/list',
    element: <VirtualAccountListPage />,
  },
]

export default paymentRoutes
