import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'

import cleanFormData from 'commons/utils/cleanFormData'

import createInternationalTransfer from '../services/createInternationalTransfer'

const FormInternationalTransfer = ({ countries }) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()

  const kirim = data => {
    const cleanData = cleanFormData(data)
    createInternationalTransfer({
      ...cleanData,
    })
      .then(({ data: { data } }) => {
        navigate(`/international-money-transfer/list`)
      })
      .catch(error => {
        toast.error(
          error.response?.data?.data?.message ||
            error ||
            'Terjadi kesalahan pada sistem. Harap coba lagi!'
        )
      })
  }

  return (
    <Form title="International Transfer" onSubmit={handleSubmit(kirim)}>
      <Controller
        name="amount"
        control={control}
        rules={{ required: 'Harap masukkan jumlah transfer' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Jumlah Transfer"
            placeholder="Masukkan jumlah transfer"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="source_country"
        control={control}
        rules={{ required: 'Harap pilih negara asal' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Negara Asal"
            options={[{ id: 'IDN', name: 'Indonesia' }]}
            placeholder="Pilih Negara Asal"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="destination_country"
        control={control}
        rules={{ required: 'Harap pilih destinasi negara' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Destinasi Negara"
            placeholder="Gunakan kode ISO 3166 (Alpha-3)"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="transaction_type"
        control={control}
        rules={{ required: 'Harap masukkan Tipe Transaksi' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Tipe Transaksi"
            options={[
              { id: 'C2C', name: 'Customer to Customer' },
              { id: 'C2B', name: 'Customer to Business' },
            ]}
            placeholder="Pilih Tipe Transaksi"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_full_name"
        control={control}
        rules={{ required: 'Masukan nama lengkap penerima' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nama Lengkap Penerima"
            placeholder="Masukkan nama lengkap penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_account_number"
        control={control}
        rules={{ required: 'Masukan Nomor akun' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Akun"
            placeholder="Masukkan Nomor Akun"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_bank_id"
        control={control}
        rules={{ required: 'Harap masukkan id bank' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Id Bank"
            placeholder="Masukkan Id bank"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_bank_name"
        control={control}
        rules={{ required: 'Harap masukkan nama bank' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nama Bank"
            placeholder="Masukkan nama bank"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_nationality"
        control={control}
        rules={{ required: 'Harap masukkan nama bank' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Kewarganegaraan Penerima"
            placeholder="Gunakan kode ISO 3166 (Alpha-3)"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="beneficiary_province"
        control={control}
        rules={{ required: 'Harap masukkan provinsi penerima' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Provinsi Penerima"
            placeholder="Masukan Provinsi Penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name="beneficiary_city"
        control={control}
        rules={{ required: 'Harap masukkan kota penerima' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Kota Penerima"
            placeholder="Masukan Kota Penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_address"
        control={control}
        rules={{ required: 'Harap masukkan alamat penerima' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Alamat Penerima"
            placeholder="Masukan Alamat Penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_relationship"
        control={control}
        rules={{
          required: 'Harap masukkan hubungan kekeluargaan dengan penerima',
        }}
        render={({ field, fieldState }) => (
          <InputField
            label="Hubungan Kekeluargaan dengan Penerima"
            placeholder="Masukan Hubungan Kekeluargaan dengan Penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_source_of_funds"
        control={control}
        rules={{ required: 'Harap masukkan sumber pendanaan penerima' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Sumber Pendanaan Penerima"
            placeholder="Masukan Sumber Pendanaan Penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_remittance_purposes"
        control={control}
        rules={{ required: 'Harap masukkan tujuan pengiriman uang' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Tujuan Pengiriman Uang"
            placeholder="Masukan tujuan pengiriman uang"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_msisdn"
        control={control}
        rules={{ required: 'Harap masukkan MSISDN' }}
        render={({ field, fieldState }) => (
          <InputField
            label="MSISDN (Mobile Subscriber Integrated Services Digital Network Number)"
            placeholder="Masukan MSIDN"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_iban"
        control={control}
        rules={{ required: 'Harap masukkan IBAN' }}
        render={({ field, fieldState }) => (
          <InputField
            label="IBAN (International Bank Account Number)"
            placeholder="Masukan IBAN"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_swift_bic_code"
        control={control}
        rules={{ required: 'Harap masukkan BIC' }}
        render={({ field, fieldState }) => (
          <InputField
            label="BIC (Bank Identifier Code)"
            placeholder="Masukan BIC"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      {/* required for british and irish bank */}
      {/* <Controller
        name="beneficiary_sort_code"
        control={control}
		rules={{ required: "Harap masukkan sort code" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Sort Code"
            placeholder="Masukan Sort Code"
            fieldState={fieldState}
			{...field}
          />
        )}
      /> */}

      {/* required for indian */}
      {/* <Controller
        name="beneficiary_ifs_code"
        control={control}
		rules={{ required: "Harap masukkan IFS code" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="IFS Code (Indian Financial System)"
            placeholder="Masukan IFS"
            fieldState={fieldState}
			{...field}
          />
        )}
      /> */}
      {/* required for Australia */}
      {/* <Controller
        name="beneficiary_bsb_number"
        control={control}
		rules={{ required: "Harap masukkan BSB number" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="BSB Number (Bank State Branch)"
            placeholder="Masukan BSB number"
            fieldState={fieldState}
			{...field}
          />
        )}
      /> */}

      {/* required for Japan */}
      {/* <Controller
        name="beneficiary_branch_number"
        control={control}
		rules={{ required: "Harap masukkan branch number" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Branch Number (Bank State Branch)"
            placeholder="Masukan branch number"
            fieldState={fieldState}
			{...field}
          />
        )}
      /> */}

      <Controller
        name="beneficiary_document_reference_number"
        control={control}
        rules={{ required: 'Harap masukkan nomor dokumen referensi' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Dokumen Referensi"
            placeholder="Masukan nomor dokumen referensi"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="beneficiary_registration_number"
        control={control}
        rules={{ required: 'Harap masukkan nomor registrasi penerima' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Registrasi Penerima"
            placeholder="Masukan nomor registrasi penerima"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      {/* required for China*/}
      {/* <Controller
        name="beneficiary_region"
        control={control}
		rules={{ required: "Harap masukkan region" }} 
        render={({ field, fieldState }) => (
          <InputField
            label="Region Penerima"
            placeholder="Masukan Region penerima"
            fieldState={fieldState}
			{...field}
          />
        )}
      /> */}

      <Controller
        name="sender_name"
        control={control}
        rules={{ required: 'Harap masukkan nama Pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nama Pengirim"
            placeholder="Masukan nama Pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_country"
        control={control}
        rules={{ required: 'Harap masukkan negara tempat inggal pengirim' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Negara Tempat Tinggal Pengirim"
            options={countries}
            placeholder="Pilih negara tempat inggal pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_place_of_birth"
        control={control}
        rules={{ required: 'Harap masukkan tempat lahir pengirim' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Negara Tempat Lahir Pengirim"
            options={countries}
            placeholder="Pilih negara tempat lahir pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_date_of_birth"
        control={control}
        rules={{ required: 'Harap masukkan tanggal lahir pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Tanggal Lahir Pengirim"
            placeholder="Masukan tempat lahir pengirim dengan format (YYYY-MM-DD)"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_address"
        control={control}
        rules={{ required: 'Harap masukkan alamat pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Alamat Pengirim"
            placeholder="Masukan alamat pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_identity_type"
        control={control}
        rules={{ required: 'Harap masukkan jenis identitas pengirim' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Jenis Identitas Pengirim"
            options={[
              { id: 'nat_id', name: 'KTP' },
              { id: 'passport', name: 'Passport' },
            ]}
            placeholder="Pilih jenis identitas pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_identity_number"
        control={control}
        rules={{ required: 'Harap masukkan nomor identitas pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Identitas Pengirim"
            placeholder="Masukan nomor identitas pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_job"
        control={control}
        rules={{ required: 'Harap masukkan pekerjaan pengirim' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Pekerjaan Pengirim"
            options={[
              { id: 'housewife', name: 'Ibu Rumah Tangga' },
              { id: 'entrepreneur', name: 'Wiraswasta' },
              { id: 'private_employee', name: 'Pegawai Swasta' },
              { id: 'government_employee', name: 'Pegawai Negeri' },
              { id: 'foundation_board', name: 'Pegawai Yayasan' },
              { id: 'indonesian_migrant_worker', name: 'TKI' },
              { id: 'company', name: 'Pengirim Merupakan Perusahaan' },
              { id: 'others', name: 'lainnya' },
            ]}
            placeholder="Pilih Pekerjaan Pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_email"
        control={control}
        rules={{ required: 'Harap masukkan email pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="e-mail Pengirim"
            placeholder="Masukan email pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_city"
        control={control}
        rules={{ required: 'Harap masukkan kota pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Kota Pengirim"
            placeholder="Masukan kota pengirim"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="sender_phone_number"
        control={control}
        rules={{ required: 'Harap masukkan nomor telepon pengirim' }}
        render={({ field, fieldState }) => (
          <InputField
            label="Nomor Telepom Pengirim"
            placeholder="Masukan nomor telepon pengirim (dimulai dengan kode nomor negara)"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <Controller
        name="product_name"
        control={control}
        rules={{ required: 'Harap masukkan nama produk' }}
        render={({ field, fieldState }) => (
          <SelectionField
            label="Nama Produk"
            options={[{ id: 'Flip', name: 'Flip' }]}
            placeholder="Pilih Nama Produk"
            fieldState={fieldState}
            {...field}
          />
        )}
      />

      <div className="card-actions justify-end">
        <Button type="submit" variant="primary">
          Kirim
        </Button>
      </div>
    </Form>
  )
}

export default FormInternationalTransfer
