import React from 'react'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
} from 'commons/components'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from 'commons/components'
import { useEffect, useState, useForm } from 'react'
import getPayment from 'payment/services/getPayment'
import EWalletTable from 'payment/components/EWalletTable'
// import getDisbursementFromAPI from 'disbursement/services/getDisbusementFromAPI'
// import updateStatus from 'disbursement/services/updateStatus'

const EWalletListPage = props => {
  const [ewallet, setEwallet] = useState([])

  useEffect(() => {
    const fetch = async () => {
      // await updateStatus()
      const { data: EWalletResponse } = await getPayment({
        table_name: 'ewallet_impl',
      })
      setEwallet(EWalletResponse.data)

      console.log(EWalletResponse)
    }

    fetch()
  }, [])

  // const update = async () => {
  //   const disbursements = await getDisbursementFromAPI()
  //   // Object.keys().map((key, index) => {
  //   //   console.log("key: " + disbursements.data[key] + ", value: " + )
  //   // })
  //   console.log(disbursements.data)
  //   // window.location.reload(true)
  //   }

  return (
    <div className="mx-auto w-full max-w-screen-xl prose p-6 flex flex-col prose">
      <div className="w-full flex justify-center sm:justify-between items-center mb-4">
        <h2 className="m-0">E-Wallet List </h2>
      </div>
      <div className="not-prose">
        <TableView {...{ ewallet }} />
      </div>
    </div>
  )
}

const TableView = ({ ewallet }) => {
  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell id="phoneNumber">Telepon</TableCell>
              <TableCell id="amount">Nominal Transaksi</TableCell>
              <TableCell id="eWalletUrl">Url</TableCell>
              <TableCell id="eWalletType">Nama E-Wallet</TableCell>
              <TableCell id="productName">Nama Produk</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ewallet &&
              ewallet.map(item => <EWalletTable key={item.id} item={item} />)}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default EWalletListPage
